import { IsMobile } from 'src/helpers/Helpers';
import HttpService from '../../system/HttpServices';

export default {
    get: (groupId) => {
        const data = {};
        if (groupId) {
            data['groupId'] = groupId;
        }
        return HttpService.get({ path: '/account/info', data, requestType: 'interaction' });
    },
    login: (email, password, universityId, universityGroupId) => {
        return HttpService.post({
            path: '/auth/login',
            data: { email, password, universityId, universityGroupId, accessType: IsMobile() ? 'mobile_web' : 'web' },
            withCredentials: true,
            requestType: 'interaction',
        });
    },
    ssoLogin: (code, universityId, universityGroupId) => {
        return HttpService.post({
            path: '/auth/login/ssoCognito',
            data: { code, universityId, universityGroupId, accessType: IsMobile() ? 'mobile_web' : 'web' },
            withCredentials: true,
            requestType: 'interaction',
        });
    },
    ssoLoginAuth: (signedData, universityId, universityGroupId) => {
        return HttpService.post({
            path: '/auth/login/sso',
            data: { signedData, universityId, universityGroupId, accessType: IsMobile() ? 'mobile_web' : 'web' },
            withCredentials: true,
            requestType: 'interaction',
        });
    },
    ssoNewLogin: (email, ssoCode, universityId, universityGroupId) => {
        const data = {
            email,
            ssoCode,
            universityId,
            accessType: IsMobile() ? 'mobile_web' : 'web',
        };
        if (universityGroupId) {
            data['universityGroupId'] = universityGroupId;
        }
        return HttpService.post({
            path: '/auth/ssoNewLogin',
            data,
            withCredentials: true,
            requestType: 'interaction',
        });
    },
    tikTokCallback: ({ code }) => {
        return HttpService.get({ path: '/auth/tiktok/callback', data: { code }, requestType: 'interaction' });
    },
    tikTokRegistration: ({
        accessToken,
        answers,
        cameFrom,
        email,
        engagementId,
        engagementType,
        first_name,
        group_id,
        last_name,
        marketing_preference,
        nationality_id,
        openId,
        university_id,
        utm_campaign,
        utm_medium,
        utm_source,
        phone_country,
        phone_number,
    }) => {
        const data = {
            email,
            name: first_name,
            last_name,
            phone_country: phone_country,
            phone_number: phone_number,
            nationality_id,
            university_id,
            medium: utm_medium,
            source: utm_source,
            campaign: utm_campaign,
            user_came_from: cameFrom,
            marketing_preference,
            answers,
            engagementData: { id: engagementId, type: engagementType === 'forum' ? 'group-chat' : engagementType },
            accessType: IsMobile() ? 'mobile_web' : 'web',
            accessToken,
            openId,
        };
        if (group_id) {
            data['group_id'] = group_id;
        }
        return HttpService.post({
            path: '/auth/tiktok/registration',
            data,
            withCredentials: true,
            requestType: 'interaction',
        });
    },
    tikTokLogin: ({ payload, universityId, universityGroupId }) => {
        const data = {
            accessToken: payload.accessToken,
            openId: payload.openId,
            userId: payload.userId,
            universityId,
            universityGroupId,
            accessType: IsMobile() ? 'mobile_web' : 'web',
        };
        return HttpService.post({
            path: '/auth/tiktok/login',
            data,
            withCredentials: true,
            requestType: 'interaction',
        });
    },
    socialLogin: (type, signedData, universityId, universityGroupId) => {
        return HttpService.post({
            path: '/auth/login/social/media/' + type,
            data: { signedData, universityId, universityGroupId, accessType: IsMobile() ? 'mobile_web' : 'web' },
            withCredentials: true,
            requestType: 'interaction',
        });
    },
    socialRegistration: (
        type,
        {
            signedData,
            email,
            nationality_id,
            phone_country,
            phone_number,
            university_id,
            university_group_id,
            utm_medium,
            utm_source,
            utm_campaign,
            cameFrom,
            marketing_preference,
            answers,
            engagementId,
            engagementType,
        }
    ) => {
        return HttpService.post({
            path: '/auth/registration/social/media/' + type,
            data: {
                signedData,
                email,
                nationality_id,
                phone_country: phone_country,
                phone_number: phone_number,
                university_id,
                university_group_id,
                medium: utm_medium,
                source: utm_source,
                campaign: utm_campaign,
                user_came_from: cameFrom,
                marketing_preference,
                answers,
                engagementData: { id: engagementId, type: engagementType === 'forum' ? 'group-chat' : engagementType },
                accessType: IsMobile() ? 'mobile_web' : 'web',
            },
            withCredentials: true,
            requestType: 'interaction',
        });
    },
    saveAcceptedTerms: (
        {
            universityId,
            utm_medium,
            utm_source,
            utm_campaign,
            cameFrom,
            marketing_preference,
            answers,
            engagementId,
            engagementType,
        },
        groupId
    ) => {
        const data = {
            universityId,
            marketing_preference,
            answers,
            medium: utm_medium,
            source: utm_source,
            campaign: utm_campaign,
            user_came_from: cameFrom,
            engagementData: { id: engagementId, type: engagementType === 'forum' ? 'group-chat' : engagementType },
        };
        if (groupId) {
            data['groupId'] = groupId;
        }
        return HttpService.post({ path: '/account/saveAcceptedTerms', data, requestType: 'interaction' });
    },
    universityQuestions: (universityId) => {
        return HttpService.get({ path: '/auth/' + universityId + '/optionalQuestions', requestType: 'interaction' });
    },
    universityQuestionsData: (universityId) => {
        return HttpService.get({
            path: '/auth/' + universityId + '/OptionalQuestionsData',
            requestType: 'interaction',
        });
    },
    saveUniversityQuestions: ({ universityId, answers, onlyMandatory, engagementId, engagementType }, groupId) => {
        const data = {
            answers,
            onlyMandatory,
            engagementData: { id: engagementId, type: engagementType === 'forum' ? 'group-chat' : engagementType },
            accessType: IsMobile() ? 'mobile_web' : 'web',
        };
        if (groupId) {
            data['groupId'] = groupId;
        }
        return HttpService.post({
            path: '/auth/' + universityId + '/saveOptionalQuestionsAnswers',
            data,
            requestType: 'interaction',
        });
    },
    mandatoryQuestions: (groupId) => {
        const data = {};
        if (groupId) {
            data['groupId'] = groupId;
        }
        return HttpService.get({ path: '/account/mandatoryQuestions', data, requestType: 'interaction' });
    },
    saveMandatoryQuestions: ({ answers }) => {
        return HttpService.post({ path: '/account/mandatoryQuestions', data: { answers }, requestType: 'interaction' });
    },
    saveUtmData: (universityId, data) => {
        return HttpService.post({ path: '/auth/' + universityId + '/utmData', data, requestType: 'interaction' });
    },
    registration: ({
        email,
        password,
        first_name,
        last_name,
        sso_custom_data,
        receive_email, // TODO: check this
        communications, // TODO: check this
        nationality_id,
        university_id,
        phone_country,
        phone_number,
        group_id,
        utm_medium,
        utm_source,
        utm_campaign,
        cameFrom,
        marketing_preference,
        answers,
        engagementId,
        engagementType,
    }) => {
        const data = {
            email,
            password,
            name: first_name,
            last_name,
            sso_custom_data,
            resive_email: receive_email,
            phone_country: phone_country,
            phone_number: phone_number,
            communications,
            nationality_id,
            university_id,
            medium: utm_medium,
            source: utm_source,
            campaign: utm_campaign,
            user_came_from: cameFrom,
            marketing_preference,
            answers,
            engagementData: { id: engagementId, type: engagementType === 'forum' ? 'group-chat' : engagementType },
            accessType: IsMobile() ? 'mobile_web' : 'web',
        };

        if (group_id) {
            data['group_id'] = group_id;
        }
        return HttpService.post({
            path: '/auth/registration',
            data,
            withCredentials: true,
            requestType: 'interaction',
        });
    },

    getUserCountry: () => {
        return HttpService.get({ path: '/data/country', requestType: 'common' });
    },

    getCountries: () => {
        return HttpService.get({ path: '/data/countries', requestType: 'public' });
    },

    getNationalities: (universityId) => {
        return HttpService.get({ path: '/data/nationalities', data: { universityId }, requestType: 'common' });
    },

    checkEmail(email) {
        return HttpService.get({ path: '/auth/unique/email', data: { email: email }, requestType: 'interaction' });
    },

    editUser(firstName, lastName) {
        return HttpService.put({ path: '/account/edit', data: { firstName, lastName }, requestType: 'interaction' });
    },

    changeEmail(email) {
        return HttpService.get({ path: '/account/edit/email', data: { email }, requestType: 'interaction' });
    },

    changeEmailConfirm(email, verification_code) {
        return HttpService.post({
            path: '/account/edit/email',
            data: { email, verification_code },
            requestType: 'interaction',
        });
    },

    changePassword(password) {
        return HttpService.get({ path: '/account/edit/password', data: { password }, requestType: 'interaction' });
    },

    changePasswordConfirm(new_password, verification_code) {
        return HttpService.post({
            path: '/account/edit/password',
            data: {
                new_password,
                verification_code,
                confirm_password: new_password,
            },
            requestType: 'interaction',
        });
    },

    changeAvatar: (avatar) => {
        return HttpService.put({
            path: '/account/edit/avatar',
            data: {
                file: avatar,
            },
            requestType: 'interaction',
        });
    },

    changeMailing(mailing, university_id) {
        return HttpService.put({
            path: '/account/edit/changeMailing',
            data: { mailing, university_id },
            requestType: 'interaction',
        });
    },

    deleteUser() {
        return HttpService.put({ path: '/account/delete', requestType: 'interaction' });
    },

    startRecovery(data) {
        return HttpService.post({ path: '/account/recovery/password', data, requestType: 'interaction' });
    },

    endRecovery(user_id, hex, new_password, confirm_password, universityId) {
        return HttpService.post({
            path: '/account/recovery/passwordConfirm',
            data: {
                user_id,
                hex,
                new_password,
                confirm_password,
                universityId,
                accessType: IsMobile() ? 'mobile_web' : 'web',
            },
            withCredentials: true,
            requestType: 'interaction',
        });
    },

    authByCode(authCode, universityId, universityGroupId) {
        return HttpService.get({
            path: '/auth/login/' + authCode,
            data: { universityId, universityGroupId, accessType: IsMobile() ? 'mobile_web' : 'web' },
            withCredentials: true,
            requestType: 'interaction',
        });
    },

    sendNotificationMethod(notificationData) {
        return HttpService.put({
            path: '/account/notificationMethod',
            data: notificationData,
            requestType: 'interaction',
        });
    },

    sendVerificationCode(verificationCode) {
        return HttpService.put({
            path: '/account/confirmNotificationMethod',
            data: verificationCode,
            requestType: 'interaction',
        });
    },

    getUniversitySsoData: (email, universityId, universityGroupId) => {
        return HttpService.get({
            path: '/auth/universitySsoData',
            data: { email, universityId, universityGroupId },
            requestType: 'interaction',
        });
    },
    sendOTPVerification(phoneNumberData) {
        return HttpService.put({
            path: '/auth/phoneNumberVerification',
            data: phoneNumberData,
            requestType: 'interaction',
        });
    },
    sendOTPVerificationConfirm(verificationData) {
        return HttpService.put({
            path: '/auth/phoneNumberVerificationConfirm',
            data: verificationData,
            requestType: 'interaction',
        });
    },
    ssoCallback(data) {
        return HttpService.post({
            path: '/auth/sso/callback',
            data,
            requestType: 'interaction',
        });
    },
    logout: (data) => {
        data.accessType = IsMobile() ? 'mobile_web' : 'web';
        return HttpService.post({ path: '/auth/logout', data, withCredentials: true, requestType: 'interaction' });
    },
};
